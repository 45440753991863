<template>
  <div class="login">
    <div class="content">
      <h1>用户登录</h1>
      <p>
        <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
      </p>
      <p>
        <label for="name"></label>
        <input type="text" id="name" v-model="userName" placeholder="用户名">
      </p>
      <p>
        <label for="password"></label>
        <input type="text" id="password" v-model="passWord" placeholder="密码">
      </p>
      <p>
        <button @click="login">登录</button>
      </p>
    </div>
  </div>
</template>

<script>
import {reqLogin} from "@/network/home";
export default {
  name: "Login",
  data() {
    return {
      userName: '',
      passWord: '',
      resMsg: '',
    }
  },
  methods: {
    login() {
      reqLogin(this.userName, this.passWord).then(res => {
        // console.log(res);
        if (res.code == 1000) {
          console.log(res);
          window.sessionStorage.setItem('Authorization',res.data)
          this.$router.push('/')
        } else {
          this.resMsg = res.msg
        }
      })
    }
  }
}
</script>

<style scoped>
.content {
  width: 60%;
  margin: 20px auto;
}

.content input {
  width: 60%;
  line-height: 30px;
  margin-bottom: 6px;
}

.content button {
  /*color: darkgreen;*/
  /*background-color: chartreuse;*/
}

</style>