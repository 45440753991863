<template>
  <div class="index">
    <div class="content">
      <h2>流信息添加</h2>
      <p>
        <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
      </p>
      <label for="name"></label>
      <input type="text" name="name" id="name" v-model="playerName" placeholder="选手">
      <label for="inUrl"></label>
      <input type="text" name="inUrl" id="inUrl" v-model="inUrl" placeholder="拉流地址">
      <label for="outUrl"></label>
      <input type="text" name="inUrl" id="inUrl" v-model="outUrl" placeholder="推流地址">

      <button @click="addMsg">添加</button>
      <h3>拉流状态<span>(用完记得断流)：</span></h3>
      <table v-for="item in proInfo">
        <tr>
          <th>选手</th>
          <th>状态</th>
        </tr>
        <template v-for="key in item">
          <tr>
            <td>{{ key.name }}</td>
            <td v-if='key.statename=="RUNNING"'><span class="green">{{ key.statename }}</span></td>
            <td v-else><span class="red">{{ key.statename }}</span></td>
          </tr>
        </template>
      </table>
      <h3>流信息记录</h3>

      <div class="stream_info">
          <ul v-for="(item,index) in rtmpInfo" :data-index="index" key="item.id">
            <li v-if="editingRow == index"><input type="text" v-model="idValue" disabled></li>
            <li v-if="editingRow == index"><input type="text" v-model="playerValue"></li>
            <li v-if="editingRow == index"><input type="text" v-model="inUrlValue"></li>
            <li v-if="editingRow == index"><input type="text" v-model="outUrlValue"></li>
            <template v-else>
              <li class="li_half">{{ item.id }}</li>
              <li class="li_half">{{ item.name }}</li>
              <li class="li_full">{{ item.inUrl }}</li>
              <li class="li_full">{{ item.outUrl }}</li>
            </template>
            <li>
              <button @click="taskStart(item)">开始</button>
              <button @click="taskStop(item)">停止</button>
              <button v-if="editingRow == index" @click="taskEditOk(item)" style="background-color: coral">修改</button>
              <button @click="taskEdit($event,item)" v-else>编辑</button>
              <button @click="taskDel(item)">删除</button>
            </li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {reqAddMsg, reqGetData, reqTaskStart, reqTaskStop, reqTaskEdit, reqTaskDel} from "@/network/home";
import {ref} from 'vue'
import * as events from "events";
import index from "vuex";

let proInfo = ref([])
let rtmpInfo = ref([])

export default {
  name: "Index",
  computed: {
    index() {
      return index
    },
    events() {
      return events
    }
  },
  created() {
    if (!window.sessionStorage.getItem('Authorization')) {
      this.$router.push('/login')
    }
    this.getData()
  },
  data() {
    return {
      playerName: '',
      inUrl: '',
      outUrl: '',
      resMsg: '',
      rtmpInfo,
      proInfo,
      editingRow: null,
      idValue: "",
      playerValue: "",
      inUrlValue: "",
      outUrlValue:"",
    }
  },
  methods: {
    getData() {
      reqGetData().then(res => {
        // console.log(res);
        if (res.code == 1000) {
          proInfo.value = res.data.ProcessInfo
          rtmpInfo.value = res.data.RtmpData
        } else if (res.code == 1006 || res.code == 1007) {
          this.resMsg = res.msg
          setTimeout(() => {
            window.sessionStorage.removeItem('Authorization')
            this.$router.push('/login')
          }, 300) // 延迟5秒
        } else {
          this.resMsg = res.msg
        }
      })
    },
    addMsg() {
      reqAddMsg(this.playerName, this.inUrl,this.outUrl).then(res => {
        if (res.code == 1000) {
          this.resMsg = "添加成功"
        }else if (res.code == 1006 || res.code == 1007) {
          this.resMsg = res.msg
          setTimeout(() => {
            window.sessionStorage.removeItem('Authorization')
            this.$router.push('/login')
          }, 300) // 延迟5秒
        } else {
          this.resMsg = res.msg
        }
      })
      setTimeout(() => {
        // 延迟后请求最新数据
        this.getData()
      }, 500) // 延迟5秒
    },
    taskStart(item) {
      reqTaskStart(item.id, item.name, item.inUrl,item.outUrl).then(res => {
        if (res.code == 1000) {
          this.resMsg = "任务启动成功"
        } else if (res.code == 1006 || res.code == 1007) {
          this.resMsg = res.msg
          setTimeout(() => {
            window.sessionStorage.removeItem('Authorization')
            this.$router.push('/login')
          }, 300) // 延迟5秒
        } else {
          this.resMsg = res.msg
        }
        setTimeout(() => {
          // 延迟后请求最新数据
          this.getData()
        }, 20000) // 延迟5秒
      })

    },
    taskStop(item) {
      reqTaskStop(item.id, item.name, item.inUrl,item.outUrl).then(res => {
        if (res.code == 1000) {
          this.resMsg = "任务停止成功"
        } else if (res.code == 1006 || res.code == 1007) {
          this.resMsg = res.msg
          setTimeout(() => {
            window.sessionStorage.removeItem('Authorization')
            this.$router.push('/login')
          }, 300) // 延迟5秒
        } else {
          this.resMsg = res.msg
        }
      })
      setTimeout(() => {
        // 延迟后请求最新数据
        this.getData()
      }, 8000) // 延迟5秒
    },
    taskEdit(event, item) {
      const index = event.target.closest('ul').dataset.index
      this.editingRow = index
      this.idValue = item.id
      this.playerValue = item.name
      this.inUrlValue = item.inUrl
      this.outUrlValue = item.outUrl
    },
    taskEditOk(item) {
      this.editingRow = null
      item.id = this.idValue
      item.name = this.playerValue
      item.inUrl = this.inUrlValue
      item.outUrl=this.outUrlValue
      reqTaskEdit(item.id, item.name, item.inUrl,item.outUrl).then(res => {
        if (res.code == 1000) {
          this.resMsg = "任务修改成功"
        } else if (res.code == 1006 || res.code == 1007) {
          this.resMsg = res.msg
          setTimeout(() => {
            window.sessionStorage.removeItem('Authorization')
            this.$router.push('/login')
          }, 300) // 延迟5秒
        } else {
          this.resMsg = res.msg
        }
      })
    },
    taskDel(item) {
      reqTaskDel(item.id, item.name, item.inUrl,item.outUrl).then(res => {
        if (res.code == 1000) {
          this.resMsg = "任务删除成功"
        } else if (res.code == 1006 || res.code == 1007) {
          this.resMsg = res.msg
          setTimeout(() => {
            window.sessionStorage.removeItem('Authorization')
            this.$router.push('/login')
          }, 300) // 延迟5秒
        } else {
          this.resMsg = res.msg
        }
      })
      setTimeout(() => {
        // 延迟后请求最新数据
        this.getData()
      }, 500) // 延迟5秒
    },
  },
}
</script>

<style scoped>
.content {
  width: 60%;
  margin: 20px auto;
}

.content input {
  width: 100%;
  line-height: 30px;
  margin-bottom: 6px;
}

.content button {
  /*color: darkgreen;*/
  /*background-color: chartreuse;*/
  cursor: pointer;
}
.content button:hover{
  background-color: #5cb85c;
  color: #ffffff;
}

.content table {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.content table th, .content table td {
  border: 1px solid #ccc;
}

.content table input {
  width: 97%;
}

.red {
  color: #d9534f;
}
.green{
  color:#5cb85c;
}

h3 span {
  color: chocolate;
  font-weight: bold;
}
.stream_info{
}
.stream_info ul{
  padding: 0;
}
.stream_info ul li{
  border: 1px solid #cccccc;
  list-style: none;
  line-height: 30px;
}
.stream_info ul li span{
  border: 1px solid #cccccc;
}
.stream_info ul li input{
  width: 99%;
}
.stream_des{
  width: 10%;
  float: left;
}
.stream_msg{
  width: 90%;
  float: left;
}
</style>