import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Index from "@/views/Index.vue";
import Login from "@/views/Login.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index,
    meta:{
      title:'转流后台'
    }
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
    meta:{
      title:'转流后台'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta:{
      title:'星际转流后台'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 全局前置守卫
router.beforeEach((to, from) => {
  document.title = to.meta.title
})
export default router
