import axios from 'axios'

import FingerprintJS from '@fingerprintjs/fingerprintjs';

// 初始化
const fpPromise = FingerprintJS.load();

// 获取指纹
fpPromise
    .then(fp => fp.get())
    .then(result => {
        // result.visitorId 为浏览器指纹
        const SerialNumber = result.visitorId
    if(SerialNumber){
        window.sessionStorage.setItem('SerialNumber', SerialNumber)
        console.log(SerialNumber);
    }
    })


export function request(config) {
//创建实例
    const instance = axios.create({
        // baseURL:'http://127.0.0.1:18080',
        timeout: 20000,
        headers:{
            'SerialNumber': window.sessionStorage.getItem('SerialNumber'),
        },
    })
//请求拦截
    instance.interceptors.request.use(config => {
        const token = window.sessionStorage.getItem('Authorization')
        if (token) {
            config.headers.Authorization = "Bearer " + `${token}`
        }
        console.log(config);
        return config

    }, err => {
        return err.response
    })
//响应拦截
    instance.interceptors.response.use(response => {
        const token = response.headers['authorization']
        if(token){
            window.sessionStorage.setItem('Authorization', token)
        }
        console.log(response);
        return response.data

    }, err => {
        return err.response
    })
//发送真正的网络请求
    return instance(config)
}