import {request} from "@/network/request";

export function reqAddMsg(playerName, inUrl,outUrl){
    return request({
        method:'post',
        url:'/api/create',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            name:playerName,
            inUrl:inUrl,
            outUrl:outUrl,
        })
    })
}

export function reqGetData(){
    return request({
        method:'get',
        url:'/api/index',
    })
}

export function reqTaskStart(id,name,inUrl,outUrl){
    return request({
        method:'post',
        url:'/api/start',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            id:id,
            name:name,
            inUrl:inUrl,
            outUrl:outUrl,
        })
    })
}

export function reqTaskStop(id,name,inUrl,outUrl){
    return request({
        method:'post',
        url:'/api/stop',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            name:name,
            inUrl:inUrl,
            outUrl:outUrl,
        })
    })
}

export function reqTaskEdit(id,name,inUrl,outUrl){
    return request({
        method:'post',
        url:'/api/edit',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            id:id,
            name:name,
            inUrl:inUrl,
            outUrl:outUrl,
        })
    })
}

// reqTaskDel
export function reqTaskDel(id,name,inUrl,outUrl){
    return request({
        method:'post',
        url:'/api/delete',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            id:id,
            name:name,
            inUrl:inUrl,
            outUrl:outUrl,
        })
    })
}

// reqLogin
export function reqLogin(name,password){
    return request({
        method:'post',
        url:'/api/login',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            userName:name,
            passWord:password,
        })
    })
}