import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    SerialNumber: null,
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setSerialNumber(state, SerialNumber) {
      state.SerialNumber = SerialNumber
    },
    clearUser(state) {
      state.user = null
      state.token = null
      state.SerialNumber = null
    }
  },
  actions: {
    login({commit}, {user, token, SerialNumber, email, nx, cash,vote}) {
      commit('setUser', user)
      commit('setToken', token)
      commit('setSerialNumber', SerialNumber)
    },
    logout({commit}) {
      commit('clearUser')
    }
  },
  modules: {
  }
})
